import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { CarrierInvoiceReceiptComponent } from "./carrier-invoice-receipt/carrier-invoice-receipt.component";

import { NgSelectModule } from "@ng-select/ng-select";
import { CarrierProcessingEdit } from "./carrier-processing-edit/carrier-processing-edit.component";
import { AllInvoiceModule } from "./allinvoice/allinvoice.module";
import { AllInvoiceComponent } from "./allinvoice/allinvoice.component";
import { SpinnerModule } from "src/app/spinner/spinner.module";
import { ModuleGuard } from "src/app/services/guard/module.guard";
import { ModuleKeys } from "src/app/models/moduleKeys";

const routes: Routes = [
  // {
  //     path: '',
  //     component: AllInvoiceComponent,
  //     loadChildren:()=>import('./allinvoice/allinvoice.module').then(m=>m.AllInvoiceModule)
  // },
  {
    path: "shipcarte/allinvoices/carrierinvoicereceipt",
    component: CarrierInvoiceReceiptComponent,
    canActivate: [ModuleGuard],
    data: { permission: ModuleKeys.INVOICES },
  },
  {
    path: "shipcarte/allinvoices/carrierprocessingEdit",
    component: CarrierProcessingEdit,
    canActivate: [ModuleGuard],
    data: { permission: ModuleKeys.INVOICES },
  },

  {
    path: "shipcarte/allinvoices",
    component: AllInvoiceComponent,
    loadChildren: () =>
      import("./allinvoice/allinvoice.module").then((m) => m.AllInvoiceModule),
    canActivate: [ModuleGuard],
    data: { permission: ModuleKeys.INVOICES },
  },
];

@NgModule({
  declarations: [
    AllInvoiceComponent,
    CarrierInvoiceReceiptComponent,
    CarrierProcessingEdit,
  ],

  imports: [
    RouterModule.forRoot(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    SpinnerModule,
  ],
})
export class InvoiceModule {}
