import { Component } from '@angular/core';
import { ScAuthService } from './services/auth/sc-auth.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ScStorageService } from './services/storage/sc-storage.service';
import { SCToastService } from './services/ngb/sctoast.service';
import { ExportAsService } from 'ngx-export-as';
import { ScHttpService } from './services/http/sc-http.service';
import { ScCommonService } from './services/common/sc-common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalculateDistanceComponent } from './modules/quick-actions/calculate-distance/calculate-distance.component';
import { ZipPostalCodeComponent } from './modules/quick-actions/zip-postal-code/zip-postal-code.component';
import { DimensionalWeightCalculatorComponent } from './modules/quick-actions/dimensional-weight-calculator/dimensional-weight-calculator.component';
import { FreightClassCalculatorComponent } from './modules/quick-actions/freight-class-calculator/freight-class-calculator.component';
import { Role } from './models/navBar';
import { elementAt } from 'rxjs/operators';
import { ModuleKeys } from './models/moduleKeys';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'shipcarte';
  version: any;
  toggle: boolean;
  searchForm: FormGroup;
  applicantId: String;
  entity: any;
  userData: any;
  image: any;
  sharedData: any;
  userPermission : string[] = [];
  moduleKeys = {
    MANUAL_ORDER:ModuleKeys.MANUAL_ORDER,
    PARTNERS:ModuleKeys.PARTNERS,
    CUSTOMERS:ModuleKeys.CUSTOMERS,
    GET_QUOTE:ModuleKeys.GET_QUOTE
  }
  sideBarList: any = [
    {
      'label': 'Dashboard',
      'href': '/shipcarte/dashboard',
      'styleCode': 'dashboard',
      'title': 'Dashboard',
      'iconLink':'fa fa-th-large',
      'data': [Role.Admin, Role.Customer, Role.User],
      "permission": ModuleKeys.DASHBOARD
    },
    {
      'label': 'Orders',
      'href': '/shipcarte/orders',
      'styleCode': 'orders',
      'title': 'Orders',
      'iconLink':'fas fa-address-card',
      'data': [Role.Admin],
      "permission": ModuleKeys.ORDERS,
      'child': [
        {
          'label': 'Get Quote',
          'href': '/shipcarte/step-one',
          'styleCode': 'getQuote',
          'title': 'Get Quote',
          'iconLink':'fa fa-usd',
          'data': [Role.Admin],
          "permission": ModuleKeys.GET_QUOTE,
        },{
          'label':'Manual Order',
          'href':'/shipcarte/orders/newOrderEntry',
          'style':'createManualOrder',
          'title':'Manual Order',
          'iconLink':'fas fa-address-card',
          'data':[Role.Admin],
          "permission": ModuleKeys.MANUAL_ORDER,
        },{
          'label':'Quote History',
          'href':'/shipcarte/quoteHistory/allQuotes',
          'styleCode':'quoteHistory',
          'title':'Quote History',
          'iconLink':'',
          'data':[Role.Admin],
          "permission": ModuleKeys.QUOTE_HISTORY,
        }
      ]
    },
    {
      'label':'ShipCarte Pro',
      'href':'javascript:void(0)',
      'styleCode':'shipcartePro',
      'title':'ShipCarte Pro',
      'iconLink':'fa fa-scribd',
      'data':[Role.Admin,Role.Partner],
      "permission": ModuleKeys.SHIPCARTE_PRO,
      'child':[
        {
          'label':'Marketplace',
          'href':'javascript:void(0)',
          'styleCode':'marketPlace',
          'title':'Marketplace',
          'iconLink':'fa fa-plus-square-o',
          'data':[Role.Admin,Role.Partner],
          "permission": ModuleKeys.SHIPCARTE_PRO,
        }
      ]
    },
    {
      'label':"Customers",
      'href':'/shipcarte/customer/list',
      'styleCode':'customers',
      'title':'Customers',
      'iconLink':'fa fa-users',
      'data':[Role.Admin],
      "permission": ModuleKeys.CUSTOMERS,
    },
    {
      'label':"Partners",
      'href':'/shipcarte/partner',
      'styleCode':'Partners',
      'title':'Partners',
      'iconLink':'fa fa-handshake-o',
      'data':[Role.Admin],
      "permission": ModuleKeys.PARTNERS,
      'child':[
        {
          'label':'Rate Upload',
          'href':'/shipcarte/rateUpload/list',
          'styleCode':'rateUpload',
          'title':'Rate Upload',
          'iconLink':'fa fa-bar-chart',
          'data':[Role.Admin],
          "permission": ModuleKeys.RATE_UPLOAD,
        }
      ]
    },
    {
      'label':'Sales Rep',
      'href':'/shipcarte/salesrep',
      'styleCode':'salesRep',
      'title':'Sales Rep',
      'iconLink':'fa fa-user',
      'data':[Role.Admin,Role.Partner],
      "permission": ModuleKeys.SALES_REP,
    },
    {
      'label':'Claims',
      'href':'/shipcarte/case/list',
      'style':'claims',
      'title':'Claims',
      'iconLink':'fa fa-book',
      'data':[Role.Admin],
      "permission": ModuleKeys.CLAIMS,
    },
    {
      'label':'Reports',
      'href':'/shipcarte/analytics/kpi-graph',
      'style':'reports',
      'title':'Reports',
      'iconLink':'fa fa-book',
      'data':[Role.Admin,Role.Partner],
      "permission": ModuleKeys.REPORTS,
      'child':[
        {
          'lable':'Invoices',
          'href':'/shipcarte/allinvoices',
          'style':'invoices',
          'title':'Invoices',
          'iconLink':'fa fa-files-o',
          'data':[Role.Admin],
          "permission": ModuleKeys.INVOICES,
        },
        {
          'label':'Promotion',
          'href':'javascript:void(0)',
          'style':'promotion',
          'title':'Promotion',
          'iconLink':'fa fa-percent',
          'data':[Role.Admin,Role.Partner],
          "permission": ModuleKeys.PROMOTION,
        }
      ]
    }
  ];
  constructor(public scAuthService: ScAuthService,
    private storageService: ScStorageService,
    private router: Router,
    private _formBuilder: FormBuilder,
    public toastService: SCToastService,
    private exportAsService: ExportAsService,
    private scHttpService: ScHttpService,
    public commonService: ScCommonService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,) {
    this.commonService.currentData.subscribe((data: any) => {
      this.sharedData = data;
    })
    let screenWidth = window.innerWidth;
    if (screenWidth <= 768) {
      this.toggle = true;
      this.sharedData.toggle = this.toggle;
    } else {
      this.toggle = false;
      this.sharedData.toggle = this.toggle;
    }
    this.version = environment.version;
    this.routeEvent(this.router);
  }
  href = window.location.hash;
  userRole: any = 'admin'
  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Navigation ended successfully
        // console.log('Navigation ended successfully');
        this.href = window.location.hash;
      }
    })
    this.commonService.dashboardData.subscribe((data=>{
      console.log(data)
      this.sharedDataDashboard=data
      // this.showChildArr=this.sharedDataDashboard.sideValue;
      console.log(this.showChildArr)
    }))

    this.scAuthService.getUserPermissions().subscribe((data)=>{
     this.userPermission = data;

    },(err)=>{
      console.log(err)
    })

    this.sideBarList.forEach((element,index)=>{
      if(element['child']){
 
        element.child.forEach((element1,index1)=>{
 
          if(this.href.includes(element1.href)){
            this.sharedDataDashboard.sideValue.push(element.href)
          }
        })
      }
    })
    
    let unauthURLS = [
      "/shipcarte/signup", "/shipcarte/user/login", "/shipcarte/signin"
    ];
    let currentURL = window.location.href;
    let uri = currentURL.split("#");
    let isCurrentURLRequiresNoLogin = false;
    if (uri && uri.length > 0) {
      isCurrentURLRequiresNoLogin = unauthURLS.includes(uri[1]);
    }
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        if (!isCurrentURLRequiresNoLogin && !this.scAuthService.isLoggedSession()) {
          this.router.navigateByUrl("shipcarte/user/login");
          this.clearLoalStrg();
          // console.log('session logout');
          //       this.router.navigateByUrl("/shipcarte/user/login");
        } else {
          // console.log('session not expired.')
          this.searchForm = this._formBuilder.group({
            searchString: ['']
          });
        }
      }
    });

    this.userData = this.scAuthService.getLoggedUserData();
    if (this.userData) {
      if (this.userData['fileId']) {
        let promise1 = this.commonService.getProfileImage(this.userData['fileId']);
        promise1.then(value => {
          this.image = this.commonService.getLocalProfileImage();
        });
      }
    }
  }

  isChildData(childData:any){
    return (childData || []).map((data:any)=>data.permission).some((data:any)=>this.userPermission.includes(data))
  }

  showChildArr = [];
  sharedDataDashboard:any;
  showChild(link) {
    // push href into array
 
    const index = this.sharedDataDashboard.sideValue.indexOf(link);
    if (index > -1) {
      this.sharedDataDashboard.sideValue = this.sharedDataDashboard.sideValue.filter(item => item !== link);
    } else {
      this.sharedDataDashboard.sideValue.push(link);
    }
    // this.sharedDataDashboard.sideValue=this.showChildArr;
  }
  isActive(url): boolean {
    return this.router.url == url;
  }

  clearLoalStrg(){
    if(localStorage.getItem('probillData')){
      this.scHttpService.removeLocalData();

    }
    else if(localStorage.getItem('_admininsuranceOpted')){
      localStorage.removeItem('_admininsuranceOpted');
    }
   else  if(localStorage.getItem('probillList')){
      localStorage.removeItem('probillList');
    }
  }

  clearAdminInvoiceParentInvID(){
    if(sessionStorage.getItem('_adminInvoiceParentInvID')){
      sessionStorage.removeItem('_adminInvoiceParentInvID');
    }
  }

  toggleMode() {
    this.toggle = !this.toggle;
    this.sharedData.toggle = this.toggle;
    // console.log(this.toggle)
  }

  showQuickActionPopupBackdrop: boolean = false;
  showHideBackdrop(val) {
    if(val == 'show'){
      this.showQuickActionPopupBackdrop = true;
    }else{
      this.showQuickActionPopupBackdrop = false;
    }
  }

  search() {
    let formData = this.searchForm.getRawValue();
    if (formData) {
      this.router.navigateByUrl("shipcarte/quote/order/list;order-num=" + formData.searchString);
    }
    this.searchForm.reset();
  }
  show = true;
  //autohide = false;

  close() {
    this.show = !this.show; //this.autohide =  !this.autohide;
    setTimeout(() => this.show = true, 5000);
  }

  routeEvent(router: Router) {
    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        let actorType = this.scAuthService.getLoggedEntityType();
        if (actorType == "customer") {
          this.authorizeRouterUrl(e.url);
        } else {
          this.scAuthService.setCustomerAprrovedAccessPage(true);
        }
      }
    });
  }

  authorizeRouterUrl(url) {
    let custApprovedURLS = ["/shipcarte/quote/shipment", "/shipcarte/quote/quote-list"];
    let isCustApprovedURL = false;
    if (url) {
      isCustApprovedURL = custApprovedURLS.includes(url);
    }
    if (isCustApprovedURL && !(this.scAuthService.isCustApproved())) {
      this.scAuthService.setCustomerAprrovedAccessPage(false);
    } else {
      this.scAuthService.setCustomerAprrovedAccessPage(true);
    }
  }

  backtoTop(){
    let element = document.getElementById('containerDiv');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: "start"})
    }
    else {
      let element = document.getElementById('page-top');
      element.scrollIntoView({ behavior: 'smooth', block: "start"})
    }
  }

  redirectToGetQuote() {
    this.router.navigateByUrl("shipcarte/step-one");
  }
  redirectToMannualOrder() {
    this.router.navigateByUrl("shipcarte/orders/newOrderEntry");
    this.clearLoalStrg();
  }
  redirectToNewCustomer() {
    this.router.navigateByUrl("shipcarte/customer/list/generalDetail");
  }
  redirectToNewPartner() {
    this.router.navigateByUrl("shipcarte/partner/generalDetails");

  }
  open_modal(modal) {
    let modalRef: any;
    if(modal == 'contentCalc'){
      modalRef = this.modalService.open(CalculateDistanceComponent, { size: 'lg', centered: false,backdropClass: 'light-blue-backdrop', backdrop: 'static' });
    }
    if(modal == 'zipmodal'){
      modalRef = this.modalService.open(ZipPostalCodeComponent, { size: 'lg', centered: false,backdropClass: 'light-blue-backdrop', backdrop: 'static' });
    }   
    if(modal == 'dimensionmodal'){
      modalRef = this.modalService.open(DimensionalWeightCalculatorComponent, { size: 'lg', centered: false,backdropClass: 'light-blue-backdrop', backdrop: 'static' });
    }   
    if(modal == 'freightmodal'){
      modalRef = this.modalService.open(FreightClassCalculatorComponent, { size: 'lg', centered: false,backdropClass: 'light-blue-backdrop', backdrop: 'static' });
    }   
    this.router.events.subscribe((val) => {
      modalRef.close();
    });
    modalRef.result.then((result) => {
    });
  }
  openFavouriteComponent() {
    this.router.navigate(['/favorite']);
  }
  openBolComponent() {
    this.router.navigate(['/quickBol/create-bol']);
  }
  openCommercialInvoice() {
    this.router.navigate(['/commercialInvoice']);
  }
}


