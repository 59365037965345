import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WelcomeComponent } from './welcome/welcome.component';
import { NotificationComponent } from './notification/notification.component';
import { FreightCalculatorComponent } from './freight-calculator/freight-calculator.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EmailDocumentComponent } from './email-document/email-document.component';
import { DownloadLabelComponent } from './download-label/download-label.component';
import { BillOfLadingComponent } from './bill-of-lading/bill-of-lading.component';
import { CommercialInvoiceComponent } from './commercial-invoice/commercial-invoice.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { CarrierContractComponent } from './carrier-contract/carrier-contract.component';
import { SignaturePopUpComponent } from './signature-popup/signature-popup.component';
import { ViewConslidatedInvoiceComponent } from './view-conslidated-invoice/view-conslidated-invoice.component';
import { ViewInsuranceComponent } from './view-insurance/view-insurance.component';
import { ViewPodComponent } from './view-pod/view-pod.component';
import { UploadPodComponent } from './upload-pod/upload-pod.component';
import { PodEmailComponent } from './pod-email/pod-email.component';
import { ViewPodRequestComponent } from './view-pod-request/view-pod-request.component';
import { ViewPodDocumentComponent } from './view-pod-document/view-pod-document.component';  
import { SpinnerModule } from 'src/app/spinner/spinner.module';
import { PipesModule } from '../pipes/pipes.module';
import { PrintInvoicePdfComponent } from './print-invoice-pdf/print-invoice-pdf.component';
import { UploadOthersDocComponent } from './upload-others-doc/upload-others-doc.component';

const routes: Routes = [
  { path: 'shipcarte/dashboard/freight-calc', component: FreightCalculatorComponent}

];

@NgModule({
  declarations: [
    WelcomeComponent, 
    NotificationComponent, 
    FreightCalculatorComponent, 
    EmailDocumentComponent, 
    DownloadLabelComponent, 
    BillOfLadingComponent,
    CommercialInvoiceComponent,
    InvoiceComponent,
    CarrierContractComponent,
    SignaturePopUpComponent,
    ViewConslidatedInvoiceComponent,
    CarrierContractComponent,
    ViewInsuranceComponent,
    ViewPodComponent,
    UploadPodComponent,
    PodEmailComponent,
    ViewPodRequestComponent,
    ViewPodDocumentComponent,
    PrintInvoicePdfComponent,
    UploadOthersDocComponent
  ],
  imports: [
    RouterModule.forRoot(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    SpinnerModule,
    PipesModule
  ],
  exports: [
    NotificationComponent, 
    EmailDocumentComponent, 
    DownloadLabelComponent, 
    BillOfLadingComponent,
    ViewConslidatedInvoiceComponent,
    CommercialInvoiceComponent,
    InvoiceComponent,
    SignaturePopUpComponent,
    ViewInsuranceComponent,
  ],
  entryComponents: [
    EmailDocumentComponent, 
    ViewConslidatedInvoiceComponent,
    DownloadLabelComponent, 
    BillOfLadingComponent,
    CommercialInvoiceComponent,
    SignaturePopUpComponent,
    ViewInsuranceComponent,
    ViewPodDocumentComponent,
    PrintInvoicePdfComponent,
    InvoiceComponent,PodEmailComponent]
})
export class CommonsModule { }
