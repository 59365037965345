import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartnerListComponent } from './partner-list/partner-list.component';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerModule } from 'src/app/spinner/spinner.module';
import { ModuleGuard } from 'src/app/services/guard/module.guard';
import { ModuleKeys } from 'src/app/models/moduleKeys';


const routes: Routes =[
  { path: '', redirectTo: 'shipcarte/signin', pathMatch: 'full' },
  { path: 'shipcarte/rateUpload/list', component: PartnerListComponent,
    canActivate: [ModuleGuard],data: { permission: ModuleKeys.RATE_UPLOAD}
  },

];
@NgModule({
  declarations: [PartnerListComponent],
  imports: [
    RouterModule.forRoot(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    SpinnerModule
  ]
})
export class PartnerModule { }
