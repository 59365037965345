import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ScHttpService } from '../http/sc-http.service';
import { ServerURLS, StorageKeys } from '../../../environments/environment';
import { ScStorageService } from '../storage/sc-storage.service';
import { BehaviorSubject } from 'rxjs';
import { getModulesForRole } from 'src/app/models/moduleKeys';

@Injectable({
  providedIn: 'root'
})
export class ScAuthService {

  token: string;
  isLoggedInSession: boolean;
  userPermission = new BehaviorSubject([]);

  isCustomerAprrovedAccessPage: boolean;

  public static LOGGED_USER = "SHIPLOGGEDINUSERID";
  public static LOGGEDIN_ENTITY_TYPE = "SHIPLOGGEDINUSERTYPE";

  constructor(private router: Router, private httpService: ScHttpService, 
    private scHttpService: ScHttpService,
  private storageService: ScStorageService) { }

  signinUser(email: string, password: String, socialProvider: String, actorType: string, postLoginURL: string) {
    //your code for checking credentials and getting tokens for signing in user

    let authPromise = this.httpService.postHttpService(ServerURLS.login.action, { "email": email, "password": password, "socialProvider": socialProvider, "actorType": actorType });
    authPromise.then((resolve) => {

      let response = resolve;

      // const permissionData = ["dashboard", "orders","getQuote","manualOrder","quoteHistory","customers","partners","rateUpload","salesRep","claims","reports","invoices","promotion","appendix","resources","settings"];

      if (response["data"]) {
        this.storageService.saveBucket("_adminentity", response["data"]);
        this.storageService.saveBucket("_admincurrentUser", email);
        const permissionData = getModulesForRole(response["userRole"] || "admin") || [];
        this.storageService.saveBucket(StorageKeys.ADMIN_USER_PERMISSION,permissionData);
        this.storageService.saveBucket(StorageKeys.LOGGED_USER, response["loggedInUserId"]);
        this.storageService.saveBucket(StorageKeys.LOGGEDIN_ENTITY_TYPE, response["entityType"]);
        this.storageService.saveBucket( StorageKeys.SALES_REP_ID, response["salesRepId"])
        this.storageService.saveBucket("_adminuserName", response["data"].name);
        this.storageService.saveBucket("_admincompletion-rate", response["data"].rate);
        this.storageService.saveBucket("_adminapplicationId", response["data"].applicationId);
        this.storageService.saveBucket("_adminisNet30Customer", response["data"].isNet30Customer);
        this.storageService.saveBucket("_adminprofileImage", response["data"]["profile_image"]);
        this.storageService.saveBucket("_adminisCustApproved", response["data"]["isApproved"]);

        this.isLoggedInSession = true;
        this.token = email;

        resolve[email];

      } else {
        // throw new Error("No data found for the user");
      }


    }).catch((error) => {
      //  ;
      // console.log(error);
      // error("No data found for the user");
    });
    return authPromise;

  }


  getUserPermissions(){
    let role = this.storageService.getBucket(StorageKeys.ADMIN_USER_PERMISSION);
    this.userPermission.next(role);
    return this.userPermission.asObservable();
  }

  clearAllStorage() {

    this.storageService.removeBucket("_adminentity");
    this.storageService.removeBucket("_admincurrentUser");
    this.storageService.removeBucket(StorageKeys.LOGGED_USER);
    this.storageService.removeBucket(StorageKeys.LOGGEDIN_ENTITY_TYPE);
    this.storageService.removeBucket(StorageKeys.SALES_REP_ID);
    this.storageService.removeBucket(StorageKeys.ADMIN_USER_PERMISSION);
    this.storageService.removeBucket("_adminuserName");
    this.storageService.removeBucket("_adminorderMetaInfo");
    this.storageService.removeBucket("_admincompletion-rate");
    this.storageService.removeBucket("_adminapplicationId");

    this.storageService.removeBucket("_adminquote");
    this.storageService.removeBucket("_adminitems");
    this.storageService.removeBucket("_adminshipment");
    this.storageService.removeBucket("_adminquoteService");
    this.storageService.removeBucket("_adminisNet30Customer");
    this.storageService.removeBucket("_adminprofileImage");
    this.storageService.removeBucket("_adminisCustApproved");

  }

  logout() {

    let authPromise = this.httpService.postHttpService(ServerURLS.logout.action, {});
    authPromise.then((resolve) => {
      this.token = null;
      
      this.isLoggedInSession = false;
      this.clearAllStorage();
      this.scHttpService.removeLocalData();

      this.router.navigate(['/shipcarte/user/login']);

    }).catch((error) => {

      if (error.code == 1412) {
        this.token = null;

        this.isLoggedInSession = false;

        this.clearAllStorage();
        this.router.navigate(['/shipcarte/user/login']);
      }

    });

    return authPromise;

  }

  isLoggedSession() {
    return this.storageService.getBucket(StorageKeys.LOGGED_USER) ? this.storageService.getBucket(StorageKeys.LOGGED_USER) : false;
  }

  getLoggedUser() {
    return this.storageService.getBucket("_admincurrentUser");
  }

  getLoggedUserData() {
    return this.storageService.getBucket("_adminentity");
  }

  getLoggedUserId() {
    return this.storageService.getBucket(StorageKeys.LOGGED_USER);
  }

  getLoggedUserName() {
    return this.storageService.getBucket("_adminuserName");
  }

  getLoggedEntityType(): any {
    return this.storageService.getBucket(StorageKeys.LOGGEDIN_ENTITY_TYPE);
  }

  isAuthenticated() {
    // here you can check if user is authenticated or not through this token 
    this.token = this.storageService.getBucket("_admincurrentUser");

    return (this.getLoggedUserId() != null && this.getLoggedUserId() != undefined && this.token != null && this.token != undefined && this.token !== '');
  }

  isAdmnAccess() {
    return this.getLoggedEntityType() == 'admin' ? true : false;
  }

  isCustAccess() {
    return this.getLoggedEntityType() == 'customer' ? true : false;
  }

  isPartnerAccess() {
    return this.getLoggedEntityType() == 'partner' ? true : false;
  }

  isUserAccess() {
    return this.getLoggedEntityType() == 'user' ? true : false;
  }
  // getApplicantId() {
  //   return this.storageService.getBucket("applicantId");

  // }

  getCreditApplicantRate() {

    return this.storageService.getBucket("completion-rate");
  }

  getCreditApplicationId() {

    return this.storageService.getBucket("applicationId");
  }

  getNet30CustomerStatus() {
    return this.storageService.getBucket("isNet30Customer");
  }

  isCustApproved() {
    return this.storageService.getBucket("isCustApproved");
  }

  setCustomerAprrovedAccessPage(val) {
    this.isCustomerAprrovedAccessPage = val;
  }

  getCustomerAprrovedAccessPage() {
    return this.isCustomerAprrovedAccessPage;
  }
}
