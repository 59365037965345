import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IPayPalConfig, ICreateOrderRequest } from "ngx-paypal";
import { ToastrService } from "ngx-toastr";
import { EnvironmentConstants } from "src/environments/environment";
@Component({
  selector: "app-paypal-payment",
  templateUrl: "./paypal-payment.component.html",
  styleUrls: ["./paypal-payment.component.scss"],
})
export class PaypalPaymentComponent implements OnInit {
  public payPalConfig?: IPayPalConfig;
  @Input() charge!: string; //* Input to set the payment amount dynamically
  @Input() currency: string = "USD"; //* Input to allow currency configuration
  @Output() onSuccess = new EventEmitter<any>(); //* Emitting an event for success
  @Output() onError = new EventEmitter<any>(); //* Emitting an event for error
  @Output() onApprove = new EventEmitter<any>(); //* Emitting an event for approve

  constructor(private toastr: ToastrService) {}

  ngOnInit() {
    this.payPalConfig = {
      currency: this.currency == "USD" ? "USD" : "CAD",
      // clientId: 'AVzgqhvGbbZUAVUodXgUdQ2Cyqp83tkS9DYYCCfAvFi9pZdBAIuxwe7tXbzX4_dfdMBGgdyuFCrxBPZ-',
      clientId: EnvironmentConstants.paypalClientId,
      createOrderOnClient: (data) =>
        <ICreateOrderRequest>{
          intent: "CAPTURE",
          purchase_units: [
            {
              amount: {
                currency_code: this.currency == "USD" ? "USD" : "CAD",
                value: this.charge || 1,
                breakdown: {
                  item_total: {
                    currency_code: this.currency == "USD" ? "USD" : "CAD",
                    value: this.charge || 1,
                  },
                },
              },
            },
          ],
        },
      advanced: {
        commit: "true",
        extraQueryParams: [{ name: "disable-funding", value: "card" }], // Disables card funding
      },
      style: {
        label: "paypal",
        layout: "horizontal",
        shape: "rect",
        color: "gold",
      },
      onApprove: (data, actions) => {
        // console.log(
        //   "onApprove - transaction was approved, but not authorized",
        //   data,
        //   actions
        // );
        actions.order.get().then((details: any) => {
          if(this.onApprove){
            this.onApprove.emit(data);
          }
          // console.log(
          //   "onApprove - you can get full order details inside onApprove: ",
          //   details
          // );
        });
      },
      onClientAuthorization: (data) => {
        if (this.onSuccess) {
          this.onSuccess.emit(data); // Emit success event
        }
      },
      onCancel: (data, actions) => {
        // console.log("OnCancel", data, actions);
        this.toastr.error("Payment got cancelled", "Sorry", {
          timeOut: 10000,
        });
      },
      onError: (err) => {
        if (this.onError) {
          this.onError.emit(err); // Call the error callback
        }
        this.toastr.error("Please Try Again", 'Sorry', {
          timeOut: 10000,
        });
      },
      onClick: (data, actions) => {
        // console.log("onClick", data, actions);
      },
    };
  }
}
