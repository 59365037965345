import { Component, OnInit, resolveForwardRef, ViewChild, ElementRef } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { ActionSequence } from 'protractor';
// import {
//   IPayPalConfig,
//   ICreateOrderRequest
// } from 'ngx-paypal';
import { ScCommonService } from 'src/app/services/common/sc-common.service';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';
import { url } from 'inspector';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingService } from 'src/app/services/loader.service';
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  @ViewChild('loadContent', undefined) loadContent;

  // public payPalConfig?: IPayPalConfig;

  customerId: any = ''
  quoteId: any = "";
  mode = true;
  paymentForm: FormGroup;
  model: any = {};
  sub: any;
  priceBr: any;
  countryListAry = [];
  countryStateListObj = {};
  stateListAry = [];
  displayData = false;
  charge: any;
  quoteActiveId: any;
  paymentStatus = false;
  paymentStatuspending = false;
  submitted: boolean;
  displayErrorMessage = true;
  errorMessage = '';
  carrierLogo = '';
  shipmentModeName: any;
  quoteCustomId = '';
  transactionId: any;
  totalWeight: number = 0;
  currentYear: any;
  isShown: boolean = true;
  card: any;
  currDiv: boolean;
  creditLimit: any;
  paymentMethod: any;
  paymentMethodToggled: any;
  profileState: any;
  isDefaultAddr: boolean = true;
  DefaultAddrdetails = [];
  cYear: any;
  isEasyPostOrder: boolean = false;
  quoteData: any = {};
  formatedQuoteItems: any[] = [];
  shipCheckoutbuttom: boolean;
  id: any;
  Pendingmsg: any;
  cardMethodSelected: boolean = true;
  FirstLastNameEnable: boolean = false;
  saveForLaterEnabled: boolean = false;
  editableOrderId = '';
  currency = '';
  insuraceData: any;
  insuranceAmount: any=0;
  weightUnit: string;
  isManualLookUpPay = false;
  paypalData:any=null;
  showErrorGoogleMapPayment=false;
  billingDataFromGoogleApiLocation

  constructor(private toastr: ToastrService, private activatedRoute: ActivatedRoute, public formBuilder: FormBuilder, private router: Router, public commonService: ScCommonService, private httpService: ScHttpService, private modalService: NgbModal,private  _loaderService: LoadingService) { }
  @ViewChild('ccNumber', undefined) ccNumberField: ElementRef;
  ngOnInit(): void {
    this._loaderService.stringSubject.next(true);
    this.customerId = sessionStorage.getItem('_admingetQuoteCustomerId');
    this.insuraceData = JSON.parse(sessionStorage.getItem('_adminorderInsuranceData'));
    // this.insuranceAmount = this.insuraceData.accepted ? this.insuraceData.amount : 0;
    this.quoteId = this.activatedRoute.snapshot.paramMap.get('quoteId');
    this.paymentMethod = "AUTHORIZENET";
    this.paymentMethodToggled = 0;
    // this.cYear =  environment.Cyear;
    this.priceBr = { quoteId: '', totalCost: 0 };
    this.creditLimit = {};
    var today = new Date();
    this.currentYear = today.getFullYear();
    // this.editableOrderId=this.commonService.editableCustomOrderId;
    this.paymentForm = this.formBuilder.group({
      creditordebit: ['', Validators.required],
      cardholdername: ['', Validators.required],
      expirationDate: ['', [Validators.required, Validators.maxLength(2), Validators.min(1), Validators.max(12)]],
      expirationDate1: ['', Validators.required],
      cvv: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: [''],
      address: ['', Validators.required],
      city: ['', Validators.required],
      zip: ['', [Validators.required, Validators.pattern("^([A-Za-z][0-9][A-Za-z][ -]?[0-9][A-Za-z][0-9]|([a-zA-Z0-9]{5,6}))$")]],
      state: ['', Validators.required],
      country: ['', Validators.required],
      company: [''],
      address2: [''],
      checkaddressDetails: [true]
    });
    this.initConfig();

    // this.quoteActiveId = id;
    this.openModel();

    let url2 = ServerURLS.getQuoteDetailsById.action + '?quoteId=' + this.quoteId;
    let promise2 = this.httpService.getHttpService(url2, '');
    promise2.then(quoteData => {

      this.quoteData = quoteData;
      this.quoteCustomId = quoteData['customQuoteId'];
      this.shipmentModeName = quoteData['shipmentModeName'];

      this.quoteData['items'].forEach((item, index) => {

        this.totalWeight += (item.wghtUnit.toLowerCase() == 'lbs' ? item.totalWeight : item.totalWeight).toFixed(2) * item.qty;
        this.weightUnit=item.wghtUnit;
        this.quoteData['items'][index]['orderId'] = '';
        this.quoteData['items'][index]['orderStatus'] = '';

        if (this.quoteData['items'][index] && this.quoteData['items'][index].unit == 'cm') {
          this.quoteData['items'][index].length = this.quoteData['items'][index].length;
          this.quoteData['items'][index].totalWeight = this.quoteData['items'][index].totalWeight;
          this.quoteData['items'][index].height = this.quoteData['items'][index].height ;
          this.quoteData['items'][index].breadth = this.quoteData['items'][index].breadth;
        }

        //this.formatedQuoteItems[index] = this.quoteData['items'][index];

      });

    });

    // this.signupService.getCountryList().subscribe(data => {
    //   this.profileState = data['success'];
    //   console.log(this.profileState)
    //   for (let key in data['success']) {
    //     if ('undefined' != typeof data['success'][key]['countryName']) {
    //       this.countryStateListObj[data['success'][key]['countryCode']] = data['success'][key]['provinceState'];
    //       this.countryListAry.push({ name: data['success'][key]['countryName'], value: data['success'][key]['countryCode'] });
    //     }
    //   }

    //   this.stateListAry = data['success'][0]['provinceState'];

    //   // this.paymentForm.controls['country'].setValue(this.countryListAry[0].value);
    //   // this.paymentForm.controls['state'].setValue(data['success'][0]['provinceState'][0].code);

    // })
    let url1 = ServerURLS.getCustomerOrderPriceComponent.action + '?quoteId=' + this.quoteId + '&customerId=' + this.customerId;
    let promise1 = this.httpService.getHttpService(url1, '');
    promise1.then((res:any) => {
      // if(res['data'] == null){
      //   this.router.navigate(["/get-quote/carrier-selection/"+localStorage.getItem('activeQuoteId')+"&=Error=No_carrier_selected"]);
      // }
      this.closeModel();
      this.priceBr = res;
      this.insuranceAmount=res.scInsurance
      this.charge = res['totalAmount'];
      this.currency = res['currency'];
      this.carrierLogo = res['carrierLogo'] ? res['carrierLogo'] : 'assets/img/payment/Courier.svg';

      if (this.priceBr['charges']) {
        let temp = [];
        this.priceBr['charges'].forEach((item, index) => {
          for (const [key, value] of Object.entries(item)) {
            temp.push([key, value]);
          }
        });

        this.priceBr['charges'] = temp;

      }


    })
    this.submitted = false;
    //
    this.isDefaultAddr = true;
    // this.id = JSON.parse(localStorage.getItem('loggedInUserDetails'));

    this.getBilling();

  }

  ngOnDestroy() {

  }

    //* Paypal function ****************************

    onSuccessPaypal(data?:any){
      this.displayData = false;
      // this.sharedData.appLoader = false;
      this.paymentMethod = "PAYPAL";
      const completedArray= ["COMPLETED","PENDING","IN_PROGRESS"];
      // const completedArray= ["COMPLETED"];
      let dataForPayment= data;
  
      if (
        this.paymentMethod === "PAYPAL" &&
        dataForPayment.status &&
        completedArray.includes(dataForPayment.status)
      ) {
        if(dataForPayment.purchase_units && 
          dataForPayment.purchase_units[0] && 
          dataForPayment.purchase_units[0].payments && 
          dataForPayment.purchase_units[0].payments.captures){
          const captureData = dataForPayment.purchase_units[0].payments.captures.find((data) =>
            completedArray.includes(data.status)
          );
          if (captureData) {
            this.paypalData = captureData;
          }
        }
      }
      if(this.paypalData){
        let name = "";
        const element = dataForPayment.purchase_units[0].shipping.address;
        if(dataForPayment.purchase_units[0].shipping && dataForPayment.purchase_units[0].shipping.address){
          if(dataForPayment.purchase_units[0].shipping.name && 
             dataForPayment.purchase_units[0].shipping.name.full_name){
              name = dataForPayment.purchase_units[0].shipping.name.full_name.split(" ");
          }
          this.paymentForm.controls["firstName"].setValue(name[0] || "");
          this.paymentForm.controls["lastName"].setValue(name[1] || "");
          this.paymentForm.controls["address"].setValue(element.address_line_1 ||"");
          this.paymentForm.controls["address2"].setValue(element.address_line_2 || "");
          this.paymentForm.controls["zip"].setValue(element.postal_code || "");
          this.paymentForm.controls["city"].setValue(element.admin_area_2 || "");
          this.paymentForm.controls["state"].setValue(element.admin_area_1 || "");
          this.paymentForm.controls["company"].setValue(element.address_line_1 || "");
          this.paymentForm.controls["country"].setValue(element.country_code || "");
        }
        this.proceed();
      }
      else{
        this._loaderService.stringSubject.next(false);
        this.toastr.error("Something went wrong", 'Sorry', {
          timeOut: 10000,
        });
      }
    }
  
    onErrorPaypal(data?:any){
      console.log('Error->>', data);
    }
  
    onApprovePaypal(data?:any){
      this._loaderService.stringSubject.next(true);
    }
  
    //* *******************************************

  removeInsurace() {
    this.displayData = true;
    this._loaderService.stringSubject.next(true);
    let url = ServerURLS.removeInsurance.action + '?quoteId=' + this.quoteId;
    let promise = this.httpService.putHttpService(url, '');
    promise.then((res: any) => {
      this.toastr.success("Insurance Removed");
      this.insuraceData.accepted = false;
      sessionStorage.setItem('_adminorderInsuranceData', JSON.stringify({
        accepted: false, certificateNumber: this.insuraceData.certificateNumber, amount: this.insuraceData.amount
      }))
      this.insuranceAmount = 0
      this.displayData = false
      this._loaderService.stringSubject.next(false);
    }).catch((error: any) => {
      this.toastr.error("Something Went Wrong!")
      this.displayData = false
      this._loaderService.stringSubject.next(false);
    })
  }
  getBilling() {
    let url = ServerURLS.getCustomerById.action + '?customerId=' + this.customerId;
    let promise = this.httpService.getHttpService(url, '');

    promise.then((response: any) => {
      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      let activeData;
      if (response.payment_location.length === 0) {
        this.FirstLastNameEnable = true;
        this.paymentForm.get('checkaddressDetails').setValue(false);
      } else {
        response.payment_location.map((element) => {
          if (element.is_default_payment_location) {
            activeData = element
            let name = element.contact_name.split(' ');
            this.paymentForm.patchValue({
              firstName: name[0],
              lastName: name[1],
              address: element.address_line1,
              address2: element.address_line2,
              zip: element.zip_code,
              city: element.city,
              company: element.company_name,
              country:element.country,
              state:element.state
            })
          }
        })
        if (!this.profileState) {

          let url = ServerURLS.getCountryList.action;
          let promise = this.httpService.getHttpService(url, '');
          promise.then((res: any) => {


            this.profileState = res['success'];
            for (let key in res['success']) {
              if ('undefined' != typeof res['success'][key]['countryName']) {
                this.countryStateListObj[res['success'][key]['countryCode']] = res['success'][key]['provinceState'];
                this.countryListAry.push({ name: res['success'][key]['countryName'], value: res['success'][key]['countryCode'] });
              }
            }
            this.stateListAry = res['success'][0]['provinceState'];
            for (let index1 = 0; index1 < Object.keys(this.profileState).length; index1++) {


              if (this.profileState[index1]['countryCode'] === activeData['country']) {

                this.paymentForm.controls["country"].setValue(this.profileState[index1]['countryCode']);

                this.stateListAry = this.profileState[index1]['provinceState']
                this.paymentForm.controls["state"].setValue(activeData['state'])
              }

            }
          })
        }

      }


    })
  }

  private initConfig(): void {
    // this.payPalConfig = {
    //   currency: this.currency == "USD" ? "USD" : "CAD",
    //   // clientId: 'AVzgqhvGbbZUAVUodXgUdQ2Cyqp83tkS9DYYCCfAvFi9pZdBAIuxwe7tXbzX4_dfdMBGgdyuFCrxBPZ-',
    //   clientId: 'Ad7fyhsfU5sPbejzljzuxlBVS7CPO6SFCDLM3FsAKuS2JZJLY3horeJNwaZ3pDr2mcRuXTjXPqN54CXh',
    //   createOrderOnClient: (data) => <ICreateOrderRequest>{
    //     intent: 'CAPTURE',
    //     purchase_units: [{
    //       amount: {
    //         currency_code: this.currency == "USD" ? "USD" : "CAD",
    //         value: this.charge,
    //         breakdown: {
    //           item_total: {
    //             currency_code: this.currency == "USD" ? "USD" : "CAD",
    //             value: this.charge
    //           }
    //         }
    //       },

    //     }]
    //   },
    //   advanced: {
    //     commit: 'true'
    //   },
    //   style: {
    //     label: 'paypal',
    //     layout: 'horizontal',
    //     shape: 'rect',
    //     color: "gold",
    //   },
    //   onApprove: (data, actions) => {
    //     console.log('onApprove - transaction was approved, but not authorized', data, actions);
    //     actions.order.get().then((details: any) => {
    //       console.log('onApprove - you can get full order details inside onApprove: ', details);
    //     });

    //   },
    //   onClientAuthorization: (data) => {
    //     console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
    //     this.toastr.success("Your Payment successfully completed.", 'Success', {
    //       timeOut: 10000,
    //     });
    //     this.displayData = false;
    //     // this.paymentStatus = true;
    //   },
    //   onCancel: (data, actions) => {
    //     console.log('OnCancel', data, actions);
    //     this.toastr.error("Payment got cancelled", 'Sorry', {
    //       timeOut: 10000,
    //     });

    //   },
    //   onError: err => {
    //     console.log('OnError', err);
    //     this.toastr.error("Please Try Again", 'Sorry', {
    //       timeOut: 10000,
    //     });
    //   },
    //   onClick: (data, actions) => {
    //     console.log('onClick', data, actions);
    //     // this.resetStatus();
    //   }
    // };
  }
  ShowDiv(divVal) {

    if (divVal == 'credit') {
      this.displayData = true;
      this._loaderService.stringSubject.next(true);
      this.paymentMethod = "SHIPCARTECREDIT";
      this.paymentMethodToggled++;
      this.skipValidation = true;
      this.openModel();
      let url = ServerURLS.getCustomerCreditData.action + '?cust_id=' + this.customerId;
      let promise = this.httpService.getHttpService(url, '');
      promise.then((result:any) => {
        this.displayData = false;
        this._loaderService.stringSubject.next(false);
        this.closeModel();



        let usdData = result['data'].filter(data => data.currency === 'USD')[0];
        let cadData = result['data'].filter(data => data.currency === 'CAD')[0];


        if (this.currency === 'USD') {
          if (parseFloat(usdData.limit) > 0) {
            if ((parseFloat(usdData.limit) - parseFloat(this.charge)) >= 0) {
              this.currDiv = false;
              this.cardMethodSelected = false;
              this.paymentMethod = 'SHIPCARTECREDIT';
              this.toastr.success('You have sufficient credit balance.', 'Success', {
                timeOut: 10000
              })
            } else {
              this.currDiv = true;
              this.cardMethodSelected = true;
              this.paymentMethod = "AUTHORIZENET";
              this.toastr.error("You don't have credit Balance.", 'Sorry', {
                timeOut: 10000,
              });
            }
          } else {
            this.paymentMethod = "AUTHORIZENET";
            this.toastr.error("You don't have credit Balance.", 'Sorry', {
              timeOut: 10000,
            });
            this.currDiv = true;
            this.cardMethodSelected = true;
          }
        } else if (this.currency === 'CAD') {
          if (parseFloat(cadData.limit) > 0) {
            if ((parseFloat(cadData.limit) - parseFloat(this.charge)) >= 0) {
              this.currDiv = false;
              this.cardMethodSelected = false;
              this.paymentMethod = 'SHIPCARTECREDIT';
              this.toastr.success('You have sufficient credit balance.', 'Success', {
                timeOut: 10000
              })
            } else {
              this.currDiv = true;
              this.cardMethodSelected = true;
              this.paymentMethod = "AUTHORIZENET";
              this.toastr.error("You don't have credit Balance.", 'Sorry', {
                timeOut: 10000,
              });
            }
          } else {
            this.paymentMethod = "AUTHORIZENET";
            this.toastr.error("You don't have credit Balance.", 'Sorry', {
              timeOut: 10000,
            });
            this.currDiv = true;
            this.cardMethodSelected = true;
          }
        }
       

      }, (error:any) => {
        this.closeModel()
        this.currDiv = true;
        this.cardMethodSelected = true;
        this.paymentMethod = "AUTHORIZENET";
        this.toastr.error(error.error.Message, 'Sorry', {
          timeOut: 10000,
        });

        if (error['status'] === 401) {
          localStorage.clear();
          this.router.navigate(['/signin']);
        }

      });


    } else {
      this.paymentMethod = "AUTHORIZENET";
      this.currDiv = true;
      this.cardMethodSelected = true;
    }

    if (this.paymentMethod == 'SHIPCARTECREDIT') {

      this.paymentForm.controls['creditordebit'].setValue("4111 1111 1111 1111");
      this.paymentForm.controls['cardholdername'].setValue("Jhone Doe");
      this.paymentForm.controls['expirationDate'].setValue("11");
      this.paymentForm.controls['expirationDate1'].setValue("2030");
      this.paymentForm.controls['cvv'].setValue("123");
    } else {
      let firstName = this.paymentForm.value['firstName'];
      let lastName = this.paymentForm.value['lastName'];
      this.paymentForm.controls['creditordebit'].setValue('');
      if (lastName !== undefined) {
        this.paymentForm.controls['cardholdername'].setValue(`${firstName} ${lastName}`);
      } else {
        this.paymentForm.controls['cardholdername'].setValue(firstName);
      }
      this.paymentForm.controls['expirationDate'].setValue('');
      this.paymentForm.controls['expirationDate1'].setValue('');
      this.paymentForm.controls['cvv'].setValue('');
    }

  }
  skipValidation: boolean = false;
  sameAsProfileAddressChecked: boolean = true;
  onSubmit(mode?) {
   
    if (mode === 'direct') {
      this.saveForLaterEnabled = false;
    }
    this.submitted = true;
    this.updateCheckoutDetail();

    // Check if the "Use Shipcarte Credit" option is selected
    // if (this.paymentMethod === 'SHIPCARTECREDIT') {
    //   this.updateCheckoutDetail();
    //   return;
    // }

    // let lastName = this.paymentForm.value['lastName']; 
    // if(!this.skipValidation&& this.paymentForm.invalid && lastName !== undefined && this.sameAsProfileAddressChecked){
    //   this.submitted = true; 
    //     // var reason = this.findInvalidControls();
    //     // this.shipCheckoutbuttom = true;
    //     // this.toastr.error('Please fill this data' + reason, 'Error', { timeOut: 10000, });
    //     return;
    // }
    // else if(this.sameAsProfileAddressChecked ==false && lastName==undefined || lastName== ""){
    //   this.submitted = true; 
    //   return;
    // }
    // else if(this.sameAsProfileAddressChecked ==true && lastName==undefined || lastName== ""){
    //   //  ;
    //   this.updateCheckoutDetail();
    // }
    // else {
    //   //  ;
    //   this.updateCheckoutDetail();
    //   // this.saveMarshInurance()
    // }

  }

  get f() { return this.paymentForm.controls; }

  getState(country) {
    this.stateListAry = [];
    for (let key in this.countryStateListObj[country]) {
      this.stateListAry.push({ name: this.countryStateListObj[country][key]['name'], code: this.countryStateListObj[country][key]['code'] });
    }

  }


  priceBrackup() {

  }

  valuechange(event) {
    if (this.currentYear > event.target.value) {
      // this.paymentForm.controls['expirationDate1'].setValue(this.currentYear);
      this.paymentForm.controls['expirationDate1'].setErrors({ 'incorrect': true });
    }
  }
  saveMarshInurance() {
    this.displayData = true;
    this._loaderService.stringSubject.next(true);
    
    // if (this.insuraceData.accepted && this.insuraceData.certificateNumber != '') {
    //   let url = ServerURLS.acceptInsurace.action + '?certificateNumber=' + this.insuraceData.certificateNumber + '&quoteId=' + this.quoteId+'&customerId='+this.customerId;
    //   let promise = this.httpService.putHttpService(url, '');
    //   promise.then((res: any) => {
    //     // this.updateCheckoutDetail();
    //     // console.log(data)
    //     this.closeModel();

    //     if (this.saveForLaterEnabled == false) {
    //       this.paymentStatus = true;
    //     }
    //     else {
    //       this.paymentStatus = false;
    //       this.saveForLaterEnabled = false;
    //     }

    //     this.displayData = false;
    //     this._loaderService.stringSubject.next(false);
    //     this.shipCheckoutbuttom = false;
    //   })
    //     .catch((err: any) => {
    //       this.displayData = false;
    //       this._loaderService.stringSubject.next(false);
    //       this.shipCheckoutbuttom = false;
    //       this.closeModel();
  
    //       this.paymentStatus = false;
          
  
    //       this.toastr.error("Unable to proceed Insurance! Please try again later")
    //       return;
    //     })
    // } else {
      // this.updateCheckoutDetail()
      this.closeModel();

      if (this.saveForLaterEnabled == false) {
        this.paymentStatus = true;
      }
      else {
        this.paymentStatus = false;
        this.saveForLaterEnabled = false;
      }

      this.displayData = false;
      this._loaderService.stringSubject.next(false);
      this.shipCheckoutbuttom = false;

    

  }
  updateCheckoutDetail() {
    if(this.paymentMethod == 'SHIPCARTECREDIT'){
      //call customerDetail API for billing address details - from default payment location list
      this.displayData = true;
      this._loaderService.stringSubject.next(true);
      let url = ServerURLS.getCustomerById.action + '?customerId=' + this.customerId;
      let promise = this.httpService.getHttpService(url, '');

      promise.then((response: any) => {
        this.displayData = false;
        this._loaderService.stringSubject.next(false);
        if (response.payment_location.length === 0) {
          this.toastr.error("There is no billing address of this customer");
        } else {
          response.payment_location.map((element) => {
            if (element.is_default_payment_location) {
              // activeData = element
              let name = element.contact_name.split(' ');
              this.paymentForm.patchValue({
                firstName: name[0],
                lastName: name[1],
                address: element.address_line1,
                address2: element.address_line2,
                zip: element.zip_code,
                city: element.city,
                company: element.company_name,
                country:element.country,
                state:element.state
              })
              this.proceed();
            }
          })
        }

      })
    }
    else{
      if(!this.paymentForm.invalid){
        this.proceed()
      }else{
        this.displayData=false;
        this._loaderService.stringSubject.next(false);
        this.paymentForm.markAllAsTouched();
        this.toastr.error("Please fill all the details");
      }
    }
  }

  proceed(){
      if (this.saveForLaterEnabled) {
        this.toastr.success("Data saved for later");
        return;
      }
     
      let detailObject = {
        "total": this.charge ? this.charge : this.priceBr['totalCost'].toString(),
        "quoteId": this.quoteId,
        "currencyUnit": this.priceBr['currency'],
        "CCN": this.paymentForm.value['creditordebit'].replace(/\s/g, ""),
        "expiryDate": this.paymentForm.value['expirationDate'] + '/' + this.paymentForm.value['expirationDate1'],
        "CVV": this.paymentForm.value['cvv'].toString(),
        "nameOnCard": this.paymentForm.value['cardholdername'],
        "serviceProvider": this.paymentMethod,
        "isDefaultAddr": this.isDefaultAddr,
        "firstName": this.paymentForm.value['firstName'],
        "lastName": this.paymentForm.value['lastName'],
        "company": this.paymentForm.value['company'],
        "address": this.paymentForm.value['address'],
        "addressLocality": this.model.aptsuite,
        "city": this.paymentForm.value['city'],
        "postalCode": this.paymentForm.value['zip'],
        "country": this.paymentForm.value['country'],
        "province": this.paymentForm.value['state']
      }

      if(this.paymentMethod == "PAYPAL"){
        detailObject["total"] = this.paypalData.amount.value;
        detailObject["currencyUnit"] = this.paypalData.amount.currency_code;
        detailObject["txn_id"] = this.paypalData.id;
      }
  
      if (this.paymentMethod == 'SHIPCARTECREDIT' || this.paymentMethod == "PAYPAL") {
        delete detailObject['CCN'];
        delete detailObject['expiryDate'];
        delete detailObject['CVV'];
        delete detailObject['nameOnCard'];
        if(this.paymentMethod == "SHIPCARTECREDIT"){
          detailObject['carrier'] = 'XP';
        }
      }
  
      
      this.openModel();
      this.displayData=true;
      this._loaderService.stringSubject.next(true);
      let url1 = ServerURLS.doPaymentOfGetQuote.action;
      let promise1 = this.httpService.postHttpService(url1, detailObject);
  
      promise1.then(result => {
        this.transactionId = result['transactionId'] ? result['transactionId'] : result['transaction id'];
        let orderInputData = {
          "paymentId": "",
          "paymtGatewayRefId": result['transactionId'] ? result['transactionId'] : result['transaction id'],
          "paymtMethod": this.paymentMethod,
          "sttsMsg": true,
          "isTerms": true,
          "quoteId": this.quoteId,
          "paymtStts": "SUCCESS",
          "freightChrgs": this.charge,
          "total": this.charge,
          "paymts": this.charge,
          "amountDue": this.charge,
          "currencyUnit": this.priceBr['currency'],
          "addrId": {
            "zipCode": this.paymentForm.value['zip'],
            "city": this.paymentForm.value['city'],
            "state": this.paymentForm.value['state'],
            "country": this.paymentForm.value['country'],
            "addrTypeId": this.quoteData['shipperAddress'].addrTypeId,
            "addrLine1": this.paymentForm.value['address'],
            "firstName": this.paymentForm.value['firstName'],
            "phoneNumber": this.quoteData['shipperAddress'].phoneNumber
          }
        }
        
        if(this.paymentMethod == "PAYPAL"){
          orderInputData["freightChrgs"] = this.paypalData.amount.value;
          orderInputData["total"] = this.paypalData.amount.value;
          orderInputData["paymts"] = this.paypalData.amount.value;
          orderInputData["amountDue"] = this.paypalData.amount.value;
          orderInputData["currencyUnit"] = this.paypalData.amount.currency_code;
        }

        let url2 = ServerURLS.saveQuotePayment.action;
        let promise2 = this.httpService.postHttpService(url2, orderInputData);
        promise2.then(data => {
          this.displayData=false;
          this._loaderService.stringSubject.next(false);
          this.saveMarshInurance()
        }, (error) => {
          this.displayData = false;
          this._loaderService.stringSubject.next(false);
          this.shipCheckoutbuttom = false;
          this.closeModel();
  
          this.paymentStatus = false;
          this.toastr.error(error['error'], 'Error', {
            timeOut: 10000,
          });
  
          return;
        })
  
      })
      .catch((err) => {
        this.displayData = false;
        this._loaderService.stringSubject.next(false);
        this.closeModel();
  
        // console.log("error", err);
        if (err['status'] === 409) {
          this.toastr.error(err['Message'], '', {
            timeOut: 10000,
          });
          this.closeModel();
          this.redirectTo(null)
          this.shipCheckoutbuttom = false;
          return;
        } else if (err['message']) {
          // console.log('error here', err)
          this.toastr.error(err['message'], '', {
            timeOut: 10000,
          });
          this.closeModel();
  
          this.shipCheckoutbuttom = false;
          return;
        }
        else {
          // console.log('error here', err)
          this.toastr.error(err['Message'], '', {
            timeOut: 10000,
          });
          this.displayData = false;
          this._loaderService.stringSubject.next(false);
          this.closeModel();
          this.shipCheckoutbuttom = false;
          return;
        }
      })
      
  }

  redirectTo(path) {
    if (!path) {
      this.paymentStatus = false;
      // this.router.navigate(['/shipment' + this.quoteActiveId]);
      this.router.navigate(['shipcarte/orders']);
      sessionStorage.removeItem('_admingetQuoteCustomerId')

    } else {
      // this.router.navigate(['shipcarte/orders/' + this.quoteActiveId+'/'+path]);
      this.router.navigate(['shipcarte/orders']);
      sessionStorage.removeItem('_admingetQuoteCustomerId')

    }
  }


  back() {
    this.router.navigate(['/shipcarte/confirm-order/' + this.quoteActiveId]);
  }

  creditCardNumberSpacing() {
    const input = this.ccNumberField.nativeElement;
    const { selectionStart } = input;
    const { creditordebit } = this.paymentForm.controls;

    let trimmedCardNum = creditordebit.value.replace(/\s+/g, '');

    if (trimmedCardNum.length > 16) {
      trimmedCardNum = trimmedCardNum.substr(0, 16);
    }

    /* Handle American Express 4-6-5 spacing */
    const partitions = trimmedCardNum.startsWith('34') || trimmedCardNum.startsWith('37')
      ? [4, 6, 5]
      : [4, 4, 4, 4];

    const numbers = [];
    let position = 0;
    partitions.forEach(partition => {
      const part = trimmedCardNum.substr(position, partition);
      if (part) numbers.push(part);
      position += partition;
    })

    this.paymentForm.get("creditordebit").setValue(numbers.join(' '));

    /* Handle caret position if user edits the number later */
    if (selectionStart < creditordebit.value.length - 1) {
      input.setSelectionRange(selectionStart, selectionStart, 'none');
    }
  }


  public findInvalidControls() {
    const invalid = [];
    const controls = this.paymentForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }


  addressDetails(event) {
    this.sameAsProfileAddressChecked = event.target.checked;
    if (event.target.checked == false) {
      let firstName = this.paymentForm.value['firstName'];
      let lastName = this.paymentForm.value['lastName']; 
      lastName = lastName !== undefined ? `${lastName}` : '';
      firstName = firstName !== undefined ? `${firstName}` : '';
      this.paymentForm.get("firstName").setValue(firstName);
      this.paymentForm.get("lastName").setValue(lastName);
      this.paymentForm.get("address").setValue('');
      this.paymentForm.get("zip").setValue('');
      this.paymentForm.get("company").setValue('');
      this.paymentForm.get("city").setValue('');
      this.paymentForm.get("country").setValue('');
      this.paymentForm.get("address2").setValue('');
      this.paymentForm.get("state").setValue('')
      this.isDefaultAddr = false;
      this.FirstLastNameEnable = true;
      // this.getBilling();
    }
    else {
      this.FirstLastNameEnable = false;
      this.isDefaultAddr = true;
      const id = JSON.parse(localStorage.getItem('_adminloggedInUserDetails'));
      this.openModel();

      this.getBilling();
      // this.address.getAddressDetails(id['defaultAddrId']).subscribe(details => {

      //   this.displayData = false;
      //   this.paymentForm.get("firstName").setValue(details['data']['firstName']);
      //   this.paymentForm.get("lastName").setValue(details['data']['lastName']);
      //   this.paymentForm.get("address").setValue(details['data']['addrLine1']);
      //   this.paymentForm.get("address2").setValue(details['data']['addrLine2']);
      //   this.paymentForm.get("zip").setValue(details['data']['zipCode']);

      //   for (let index1 = 0; index1 < Object.keys(this.profileState).length; index1++) {
      //     if (this.profileState[index1]['countryCode'] === details['data']['country']) {

      //       this.paymentForm.get("country").setValue(this.profileState[index1]['countryCode']);

      //       this.stateListAry=this.profileState[index1]['provinceState']
      //       this.paymentForm.controls["state"].setValue(details['data']['state'])

      //     }
      //   }
      //   this.paymentForm.controls["city"].setValue(details['data']['city']);
      //   this.paymentForm.get("company").setValue(details['data']['company']);
      //   // this.getState(details['data']['country']);
      //   // this.paymentForm.get("state").setValue(details['data']['state'])


      // },
      //   err => {
      //     this.displayData = false;
      //     if (err['status'] === 409) {
      //       this.toastr.error(err['error']['Message'], '', {
      //         timeOut: 10000,
      //       });
      //       this.displayData = false;

      //       return;
      //     } else {
      //       this.toastr.error(err['error']['Message'], '', {
      //         timeOut: 10000,
      //       });
      //       this.displayData = false;
      //       return;
      //     }

      //   })
    }

  }
  saveForLater() {
    this.saveForLaterEnabled = true;
    this.router.navigateByUrl('shipcarte/quoteHistory/allQuotes')
    // this.onSubmit();
  }
  checkDiscountValue(val: string) {
    if (val.charAt(0) == '-') {
      return '(-) $ ' + val.slice(1);
    } else {
      return '$ ' + val;
    }
  }
  goBackToStep3() {
    this.router.navigateByUrl('shipcarte/confirm-order/' + this.quoteId)
  }
  openModel() {
    // this.modalService.open(this.loadContent, { centered: true, backdropClass: 'light-blue-backdrop,', windowClass: 'dark-modelPayment', backdrop: 'static' })
  }
 
  toggleManualLookUp() {
      this.isManualLookUpPay = !this.isManualLookUpPay;
      this.paymentForm.get("address").setValue('');
      this.paymentForm.get("zip").setValue('');
      // this.paymentForm.get("company").setValue('');
      this.paymentForm.get("city").setValue('');
      this.paymentForm.get("country").setValue('');
      this.paymentForm.get("address2").setValue('');
      this.paymentForm.get("state").setValue('')
    
  }

  handleAddressChange(dataAddress, factor) {
    this.getzipcodestart(
      dataAddress.formatted_address,
      dataAddress.address_components,
      dataAddress.geometry.location,
      factor,
      dataAddress.name,
    );
    this.setDataFromGoogleLocationOrErrorForBillingLocation()
  }
  setDataFromGoogleLocationOrErrorForBillingLocation() {
    this.paymentForm.patchValue({
      address: this.billingDataFromGoogleApiLocation.sourceAddress.fullAddr,
      address2: this.billingDataFromGoogleApiLocation.sourceAddress.addrLine1,
      zip: this.billingDataFromGoogleApiLocation.sourceAddress.zipCode,
      city: this.billingDataFromGoogleApiLocation.sourceAddress.city,
      // company: this.billingDataFromGoogleApiLocation.sourceAddress.companyName,
      country: this.billingDataFromGoogleApiLocation.sourceAddress.country,
      state: this.billingDataFromGoogleApiLocation.sourceAddress.state,
    })

    if (
      (this.billingDataFromGoogleApiLocation.sourceAddress.addrLine1.length == 0 &&
        this.billingDataFromGoogleApiLocation.sourceAddress.addrLine2.length == 0) ||
      this.billingDataFromGoogleApiLocation.sourceAddress.country.length == 0 ||
      this.billingDataFromGoogleApiLocation.sourceAddress.city.length == 0 ||
      this.billingDataFromGoogleApiLocation.sourceAddress.state.length == 0 ||
      this.billingDataFromGoogleApiLocation.sourceAddress.zipCode.length == 0
    ) {
      this.showErrorGoogleMapPayment = true;
    }
    else {
      this.showErrorGoogleMapPayment = false;
    }


  }
  stringContainerNumbersOrNot(name) {
    let num = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
    let dataPresent = false;
    for (let element of num) {
      if (name.includes(element)) {
        dataPresent = true;
      }
    }
    return dataPresent

  }
  
  getzipcodestart(formatted_address, address, geometry, factor, companyName) {
    // this.showErrorGoogleMap = false;
    let tempObjSet = {
      sourceAddress: {
        companyName: this.stringContainerNumbersOrNot(companyName) ? '' : companyName,
        latitude: '',
        longitude: '',
        fullAddr: '',
        addrLine1: '',
        addrLine2: '',
        country: '',
        city: '',
        state: '',
        zipCode: '',
        website: ''
      }
    }

    if (geometry.lat()) {
      tempObjSet['sourceAddress']['latitude'] = geometry.lat();
      tempObjSet['sourceAddress']['longitude'] = geometry.lng();
      tempObjSet['sourceAddress']['fullAddr'] = formatted_address;
    }

    //get city and country and zipCode
    for (let key in address) {
      if (address[key]['types'][0] == 'country') {
        tempObjSet['sourceAddress']['country'] =
          address[key]['short_name'];
      }

      //select city
      if (
        address[key]['types'][0] == 'locality' ||
        address[key]['types'][0] == 'sublocality_level_1'
      ) {
        tempObjSet['sourceAddress']['city'] = address[key]['short_name'];
      }

      //select state
      if (address[key]['types'][0] == 'administrative_area_level_1') {
        tempObjSet['sourceAddress']['state'] = address[key]['short_name'];
      }



      //select postal_code
      if (address[key]['types'][0] == 'postal_code') {
        tempObjSet['sourceAddress']['zipCode'] = address[key]['long_name'];
      }

      //select addressLine1 and 2
      var stepStart = 0;
      if (
        !tempObjSet['sourceAddress']['addrLine1'] &&
        address[key]['types'][0] === 'subpremise'
      ) {
        stepStart++;
        tempObjSet['sourceAddress']['addrLine1'] =
          address[key]['long_name'];
      }
      if (
        !tempObjSet['sourceAddress']['addrLine1'] &&
        address[key]['types'][0] === 'street_number'
      ) {
        stepStart++;
        tempObjSet['sourceAddress']['addrLine1'] =
          address[key]['long_name'];
      }

      if (
        !tempObjSet['sourceAddress']['addrLine1'] &&
        address[key]['types'][0] === 'route'
      ) {
        stepStart++;
        tempObjSet['sourceAddress']['addrLine1'] =
          address[key]['long_name'];
      }

      if (
        tempObjSet['sourceAddress']['addrLine1'] &&
        address[key]['types'][0] === 'route'
      ) {
        stepStart++;
        tempObjSet['sourceAddress']['addrLine2'] =
          address[key]['long_name'];
      }
      if (
        !tempObjSet['sourceAddress']['addrLine1'] &&
        !stepStart
      ) {
        //tempObjSet['sourceAddress']['addrLine1'] = formatted_address.split(", ")[0];
        //tempObjSet['sourceAddress']['addrLine2'] = formatted_address.split(", ")[1];
        //tempObjSet['sourceAddress']['addrLine1'] = " ";
        //tempObjSet['sourceAddress']['addrLine2'] = " ";
        // this.showErrorGoogleMap = true;
        // return;
      }

        this.billingDataFromGoogleApiLocation = tempObjSet;

    }
  }
  closeModel() {
    this.modalService.dismissAll();
  }
}
