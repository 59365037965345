import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { Routes, RouterModule } from '@angular/router';
import {DragDropModule} from '@angular/cdk/drag-drop'
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { SpinnerModule } from 'src/app/spinner/spinner.module';
import { ModuleGuard } from 'src/app/services/guard/module.guard';
import { ModuleKeys } from 'src/app/models/moduleKeys';
const routes: Routes = [
  { path: '', redirectTo: 'shipcarte/signin', pathMatch: 'full' },
  { path: 'shipcarte/dashboard', 
    component: DashboardComponent, 
    canActivate: [ModuleGuard],
    data: { permission: ModuleKeys.DASHBOARD}
  }

];

@NgModule({
  declarations: [DashboardComponent],
  imports: [
    RouterModule.forRoot(routes),
    CommonModule,
    NgSelectModule,
    ReactiveFormsModule,
    DragDropModule,
    SpinnerModule
  ]
})
export class DashboardModule { }