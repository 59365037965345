import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseDocumentComponent } from './case-document/case-document.component';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CaseListComponent } from './case-list/case-list.component';
import { MyCaseListComponent } from './my-case-list/my-case-list.component';
import { CaseViewComponent } from './case-view/case-view.component';
import { CaseHistoryListComponent } from './case-history-list/case-history-list.component';
import { SpinnerModule } from 'src/app/spinner/spinner.module';
import { ModuleGuard } from 'src/app/services/guard/module.guard';
import { ModuleKeys } from 'src/app/models/moduleKeys';

const routes: Routes =[
  { path: '', redirectTo: 'shipcarte/case/document/add', pathMatch: 'full' },
  { path: 'shipcarte/case/document/add', component: CaseDocumentComponent,canActivate: [ModuleGuard], data: { permission: ModuleKeys.CLAIMS} },
  { path: 'shipcarte/case/list', component: CaseListComponent,canActivate: [ModuleGuard], data: { permission: ModuleKeys.CLAIMS} },
  { path: 'shipcarte/my/case/list', component: MyCaseListComponent, canActivate: [ModuleGuard], data: { permission: ModuleKeys.CLAIMS} },
  { path: 'shipcarte/case/view', component: CaseViewComponent,canActivate: [ModuleGuard], data: { permission: ModuleKeys.CLAIMS} },
  { path: 'shipcarte/case/history/list', component: CaseHistoryListComponent,canActivate: [ModuleGuard], data: { permission: ModuleKeys.CLAIMS} }

];

@NgModule({
  declarations: [CaseDocumentComponent, CaseListComponent, MyCaseListComponent, CaseViewComponent, CaseHistoryListComponent],
  imports: [
    RouterModule.forRoot(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    SpinnerModule
  ]
})
export class CaseModule { }
