import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { ScAuthService } from "../auth/sc-auth.service";

@Injectable({
  providedIn: "root",
})
export class ModuleGuard implements CanActivate {
  constructor(private authService: ScAuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let userpermissions = [];
    this.authService.getUserPermissions().subscribe((data) => {
      userpermissions = data;
    });
    if (userpermissions) {
      if (
        route.data.permission &&
        !userpermissions.includes(route.data.permission)
      ) {
        return false;
      } else {
        return true;
      }
    }
  }
}
