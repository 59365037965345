import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ModuleKeys } from "src/app/models/moduleKeys";
import { ScAuthService } from "src/app/services/auth/sc-auth.service";
import { ListDataServiceService } from "src/app/services/list-data-service.service";

@Component({
  selector: "app-setting",
  templateUrl: "./setting.component.html",
  styleUrls: ["./setting.component.scss"],
})
export class SettingsComponent implements OnInit {
  tabs = [
    { id: 1, tabName: "Standard Charges", permission:ModuleKeys.SETTINGS_STANDARD_CHARGES },
    { id: 2, tabName: "Package Manager", permission:ModuleKeys.SETTINGS_PACKAGE_MANAGER},
    { id: 3, tabName: "Customer User Management",permission:ModuleKeys.SETTINGS_CUSTOM_USER_MGT },
    { id: 4, tabName: "Admin User Management",permission:ModuleKeys.SETTINGS_ADMIN_USER_MGT },
  ];
  

  selectedTab: number = 1;

  ngOnInit(): void {
    this.scAuthService.getUserPermissions().subscribe((data)=>{
      this.tabs = this.tabs.filter((dataFilter)=> data.includes(dataFilter.permission));
 
     },(err)=>{
       console.log(err)
     })
    this.setTabId();
    this.listDataService.removeAllOrderListData();
    this.listDataService.removeAllDataOfCustomersList();
    this.listDataService.removeAllDataOfPartnerList();
    this.listDataService.removeAllDataOfQuoteHistory();
    this.listDataService.removeAllDataOfInvoiceList();
    this.listDataService.removeAllDataOfToAuditInvoiceList();
    this.listDataService.removeAllDataOfToInvoiceList();
    this.listDataService.removeAllDataOfToContractProcessingList();
    this.listDataService.removeAllSalesRepData();
  }
  constructor(
    private router: Router,
    private listDataService: ListDataServiceService,
    public scAuthService: ScAuthService
  ) {}
  ngOnDestroy() {
    let value = this.listDataService.getValueForUserDetailIsEnableValue();
    if (!value) {
      this.listDataService.removeAllDataOfSettingpackageManageer();
      this.listDataService.removeAllDataOfStandardChargeList();
      this.listDataService.removeAllDataSettingUserManagement();
      this.listDataService.removeAllDataSettingAdminManagement();
    }
  }
  selectTab(id) {
    this.selectedTab = id;
    if (id == 1) {
      this.router.navigateByUrl("shipcarte/settings");
    } else if (id == 2) {
      this.router.navigateByUrl("shipcarte/settings/packageManager");
    } else if (id == 3) {
      this.router.navigateByUrl("shipcarte/settings/userManagement");
    } else if (id == 4) {
      this.router.navigateByUrl("shipcarte/settings/adminManagement");
    }
  }

  setTabId() {
    let urlArray = window.location.href.split("/");
    if (urlArray[urlArray.length - 1] === "userManagement") {
      this.selectedTab = 3;
    } else if (urlArray[urlArray.length - 1] === "standardCharges") {
      this.selectedTab = 1;
    } else if (urlArray[urlArray.length - 1] === "packageManager") {
      this.selectedTab = 2;
    } else if (urlArray[urlArray.length - 1] === "adminManagement") {
      this.selectedTab = 4;
    }
  }
}
