import { Component, Input, OnInit } from '@angular/core';
import { ScHttpService } from 'src/app/services/http/sc-http.service';
import { ServerURLS } from 'src/environments/environment';
import { ElementRef, ViewChild } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-upload-others-doc',
  templateUrl: './upload-others-doc.component.html',
  styleUrls: ['./upload-others-doc.component.scss']
})
export class UploadOthersDocComponent implements OnInit {
  @Input() uploadOrderId;
  @ViewChild('fileInput',undefined) fileInput: ElementRef;
  uploadStatus: boolean=false;
  constructor( private httpService: ScHttpService,private  _loaderService: LoadingService,private http: HttpClient,public activeModal: NgbActiveModal,private toastService: ToastrService) { }

  ngOnInit() {
  }
  clearFile(){
    this.activeModal.close();
  }
  upload() {
   // this._loaderService.stringSubject.next(true);
    this.uploadStatus=true

    const fileInput: HTMLInputElement = this.fileInput.nativeElement;


    if (fileInput.files && fileInput.files.length > 0) {
        const file: File = fileInput.files[0];

        // Create a FormData object to append the file
        const formData: FormData = new FormData();
        formData.append('orderId', this.uploadOrderId);
        formData.append('othersDocumentFile', file);

        let url = ServerURLS.uploadOthersDoc.action;

        // Make the HTTP request with FormData and headers
        const headers = new HttpHeaders(); 
        this.http.post(url, formData, { headers }).subscribe(
            (response: any) => {
                // Handle the response if needed
                this.toastService.success('File uploaded successfully ')
                //this._loaderService.stringSubject.next(false);
                this.uploadStatus=false


                this.activeModal.close();
            },
            (error: any) => {
              this.uploadStatus=false

                // Handle the error if needed
                this.toastService.error('Document is already present')
                console.error(error);
            }
        );
    } else {
      this.uploadStatus=false

        this.toastService.error('No file selected ')
    }
    
}

}
