import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaypalPaymentComponent } from './paypal-payment/paypal-payment.component';
import { NgxPayPalModule } from 'ngx-paypal';

@NgModule({
  declarations: [PaypalPaymentComponent],
  imports: [
    CommonModule,
    NgxPayPalModule,
  ],
  exports:[
    PaypalPaymentComponent
  ]
})
export class PaymentModule { }
