import { Role } from "./navBar";

export enum ModuleKeys {
  DASHBOARD = "dashboard",
   // orders *************************************
  ORDERS = "orders",
  GET_QUOTE = "getQuote",
  MANUAL_ORDER = "manualOrder",
  QUOTE_HISTORY = "quoteHistory",
  // shipcarte pro *************************************
  SHIPCARTE_PRO = "shipcartePro",
  // customers *************************************
  CUSTOMERS = "customers",
  CUSTOMERS_GENERAL_DETAILS = "customersGenerealDetail",
  CUSTOMERS_ACCOUNT_INFORMATION = "customersAccountInfo",
  CUSTOMERS_SHIPPING_RECEIVING = "customerShippingReceiving",
  CUSTOMERS_CUSTOMS = "customersCustoms",
  CUSTOMERS_CREDIT = "customersCredit",
  CUSTOMERS_USERS = "customersUsers",
  CUSTOMERS_CARRIERS = "customersCarriers",
  CUSTOMERS_PACKAGE_MANAGER = "customersPackageManager",
  CUSTOMERS_ORDER_HISTORY = "customersOrderHistory",
  // partners *************************************
  PARTNERS = "partners",
  RATE_UPLOAD = "rateUpload",
  // sales rep *************************************
  SALES_REP = "salesRep",
  CLAIMS = "claims",
  REPORTS = "reports",
  INVOICES = "invoices",
  PROMOTION = "promotion",
  APPENDIX = "appendix",
  RESOURCES = "resources",
  // Settings *************************************
  SETTINGS = "settings",
  SETTINGS_STANDARD_CHARGES = "settingStandardCharges",
  SETTINGS_PACKAGE_MANAGER = "settingPackageManager",
  SETTINGS_CUSTOM_USER_MGT = "settingCustomUserMgt",
  SETTINGS_ADMIN_USER_MGT = "settingAdminUserMgt",
}

//* Define the role-based mapping
const roleModuleMapping: { [role: string]: ModuleKeys[] } = {
  [Role.Admin]: [
    ModuleKeys.DASHBOARD,
    ModuleKeys.ORDERS,
    ModuleKeys.GET_QUOTE,
    ModuleKeys.MANUAL_ORDER,
    ModuleKeys.QUOTE_HISTORY,
    ModuleKeys.SHIPCARTE_PRO,
    ModuleKeys.CUSTOMERS,
    ModuleKeys.CUSTOMERS_ACCOUNT_INFORMATION,
    ModuleKeys.CUSTOMERS_GENERAL_DETAILS,
    ModuleKeys.CUSTOMERS_SHIPPING_RECEIVING,
    ModuleKeys.CUSTOMERS_CUSTOMS,
    ModuleKeys.CUSTOMERS_CREDIT,
    ModuleKeys.CUSTOMERS_USERS,
    ModuleKeys.CUSTOMERS_CARRIERS,
    ModuleKeys.CUSTOMERS_PACKAGE_MANAGER,
    ModuleKeys.CUSTOMERS_ORDER_HISTORY,
    ModuleKeys.PARTNERS,
    ModuleKeys.RATE_UPLOAD,
    ModuleKeys.SALES_REP,
    ModuleKeys.CLAIMS,
    ModuleKeys.REPORTS,
    ModuleKeys.INVOICES,
    ModuleKeys.PROMOTION,
    ModuleKeys.APPENDIX,
    ModuleKeys.RESOURCES,
    ModuleKeys.SETTINGS,
    ModuleKeys.SETTINGS_ADMIN_USER_MGT,
    ModuleKeys.SETTINGS_CUSTOM_USER_MGT,
    ModuleKeys.SETTINGS_PACKAGE_MANAGER,
    ModuleKeys.SETTINGS_STANDARD_CHARGES,
  ],
  [Role.SalesRep]: [
    ModuleKeys.DASHBOARD,
    ModuleKeys.ORDERS,
    ModuleKeys.GET_QUOTE,
    // ModuleKeys.MANUAL_ORDER,
    ModuleKeys.QUOTE_HISTORY,
    // ModuleKeys.SHIPCARTE_PRO,
    ModuleKeys.CUSTOMERS,
    // ModuleKeys.CUSTOMERS_ACCOUNT_INFORMATION,
    ModuleKeys.CUSTOMERS_GENERAL_DETAILS,
    // ModuleKeys.CUSTOMERS_SHIPPING_RECEIVING,
    // ModuleKeys.CUSTOMERS_CUSTOMS,
    // ModuleKeys.CUSTOMERS_CREDIT,
    // ModuleKeys.CUSTOMERS_USERS,
    // ModuleKeys.CUSTOMERS_CARRIERS,
    ModuleKeys.CUSTOMERS_PACKAGE_MANAGER,
    ModuleKeys.CUSTOMERS_ORDER_HISTORY,
    // ModuleKeys.PARTNERS,
    // ModuleKeys.RATE_UPLOAD,
    // ModuleKeys.SALES_REP,
    ModuleKeys.CLAIMS,
    ModuleKeys.REPORTS,
    ModuleKeys.INVOICES,
    ModuleKeys.PROMOTION,
    ModuleKeys.APPENDIX,
    ModuleKeys.RESOURCES,
    // ModuleKeys.SETTINGS,
    // ModuleKeys.SETTINGS_ADMIN_USER_MGT,
    // ModuleKeys.SETTINGS_CUSTOM_USER_MGT,
    // ModuleKeys.SETTINGS_PACKAGE_MANAGER,
    // ModuleKeys.SETTINGS_STANDARD_CHARGES,
  ],
  [Role.Operation]: [
    ModuleKeys.DASHBOARD,
    ModuleKeys.ORDERS,
    ModuleKeys.GET_QUOTE,
    ModuleKeys.MANUAL_ORDER,
    ModuleKeys.QUOTE_HISTORY,
    // ModuleKeys.SHIPCARTE_PRO,
    // ModuleKeys.CUSTOMERS,
    ModuleKeys.PARTNERS,
    ModuleKeys.RATE_UPLOAD,
    // ModuleKeys.SALES_REP,
    ModuleKeys.CLAIMS,
    ModuleKeys.REPORTS,
    ModuleKeys.INVOICES,
    ModuleKeys.PROMOTION,
    ModuleKeys.APPENDIX,
    ModuleKeys.RESOURCES,
    ModuleKeys.SETTINGS,
    ModuleKeys.SETTINGS_ADMIN_USER_MGT,
    ModuleKeys.SETTINGS_CUSTOM_USER_MGT,
    ModuleKeys.SETTINGS_PACKAGE_MANAGER,
    ModuleKeys.SETTINGS_STANDARD_CHARGES,
  ],
  [Role.Finance]: [
    ModuleKeys.DASHBOARD,
    ModuleKeys.ORDERS,
    // ModuleKeys.GET_QUOTE,
    // ModuleKeys.MANUAL_ORDER,
    // ModuleKeys.QUOTE_HISTORY,
    // ModuleKeys.SHIPCARTE_PRO,
    ModuleKeys.CUSTOMERS,
    ModuleKeys.CUSTOMERS_ACCOUNT_INFORMATION,
    ModuleKeys.CUSTOMERS_GENERAL_DETAILS,
    ModuleKeys.CUSTOMERS_SHIPPING_RECEIVING,
    ModuleKeys.CUSTOMERS_CUSTOMS,
    ModuleKeys.CUSTOMERS_CREDIT,
    ModuleKeys.CUSTOMERS_USERS,
    ModuleKeys.CUSTOMERS_CARRIERS,
    ModuleKeys.CUSTOMERS_PACKAGE_MANAGER,
    ModuleKeys.CUSTOMERS_ORDER_HISTORY,
    ModuleKeys.PARTNERS,
    // ModuleKeys.RATE_UPLOAD,
    ModuleKeys.SALES_REP,
    ModuleKeys.CLAIMS,
    ModuleKeys.REPORTS,
    ModuleKeys.INVOICES,
    ModuleKeys.PROMOTION,
    ModuleKeys.APPENDIX,
    ModuleKeys.RESOURCES,
    ModuleKeys.SETTINGS,
    // ModuleKeys.SETTINGS_ADMIN_USER_MGT,
    // ModuleKeys.SETTINGS_CUSTOM_USER_MGT,
    // ModuleKeys.SETTINGS_PACKAGE_MANAGER,
    ModuleKeys.SETTINGS_STANDARD_CHARGES,
  ],
};

//* Function to get the allowed modules based on role
export const getModulesForRole = (role: string): ModuleKeys[] => {
  return roleModuleMapping[role] || [];
};
